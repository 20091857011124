














































import { ACHIEVEMENTS, automaticAchievementUnlocking } from "@/api/achievementService";
import { userModule } from "@/store/modules/user";
import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
  name: "ConnectDiscordButton",
  setup() {
    const vm = reactive({
      dialog: false,
    });

    const isSeller = userModule.state.user.seller;

    async function connect() {
      if (userModule.state.user.discordUID) {
        window.open("https://discord.gg/JDSYuYZSpf");
        await automaticAchievementUnlocking(ACHIEVEMENTS.DISCORD);
      }
      else {
        if (isSeller) {
          window.open("https://discord.com/api/oauth2/authorize?client_id=859073207876452354&redirect_uri=http%3A%2F%2Flocalhost%3A1337%2Fdiscord-tilkobling%2F&response_type=token&scope=guilds.join%20identify");
        }
        else {
          vm.dialog = true;
        }
      }
    }

    function goToDiscordFromDialog() {
      window.open("https://discord.com/api/oauth2/authorize?client_id=859073207876452354&redirect_uri=https%3A%2F%2Fapp.learnlink.no%2Fdiscord-tilkobling%2F&response_type=token&scope=identify%20guilds.join");
      // for testing in dev:
      // window.open("https://discord.com/api/oauth2/authorize?client_id=859073207876452354&redirect_uri=http%3A%2F%2Flocalhost%3A1337%2Fdiscord-tilkobling%2F&response_type=token&scope=guilds.join%20identify");
      vm.dialog = false;
    }

    return {
      connect,
      goToDiscordFromDialog,
      userModule,
      vm,
    };
  }
});

