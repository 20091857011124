<template>
  <v-alert
    type="info"
    shaped
    text
    width="400"
    class="mx-auto"
  >
    <small>
      <b>Teknisk support</b><br>
      <span style="font-size: 18px"><b>
        Trenger dere hjelp?
      </b></span><br>
      <a
        target="_blank"
        href="https://learnlink.no/support"
      >
        www.learnlink.no/support
      </a>
    </small>
  </v-alert>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "SupportPhoneAlert",
});
</script>
