




























































import { computed, defineComponent, PropType } from "@vue/composition-api";
import { getReadableTimeForTeam } from "@/helpers/timeUtils";
import { userModule } from "@/store/modules/user";
import ConnectDiscordButton from "../settings/ConnectDiscordButton.vue";
import Learnlink from "@learnlink/interfaces";
import ProfilePhoto from "@/components/profile/ProfilePhoto.vue";
import StartOnlineSessionButton from "./StartOnlineSessionButton.vue";

export default defineComponent({
  components: { ProfilePhoto, ConnectDiscordButton, StartOnlineSessionButton },
  name: "TeamCardHeader",
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.FullMetadata>,
      required: true
    }
  },
  setup(props) {
    const readableTime = computed(() => getReadableTimeForTeam(props.team.teamSessions || [], props.team.time));
    const sellerName = computed(() => props.team.verboseSellers[0]?.firstName);

    return {
      isSeller: userModule.state.user.role === "seller",
      readableTime,
      sellerName
    };
  }
});
