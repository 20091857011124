

















import { defineComponent, PropType } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "Credentials",
  props: {
    accounts: {
      type: Array as PropType<Learnlink.Customer.ForVerboseTeam[]>,
      required: true
    }
  },
  setup() {
    function getUsername(account: Learnlink.Customer.ForVerboseTeam): string {
      return account.credentials[0]?.username || "Mangler brukernavn";
    }
    function getPassword(account: Learnlink.Customer.ForVerboseTeam): string {
      return account.credentials[0]?.password || "Mangler passord";
    }
    return {
      getPassword,
      getUsername,
    };
  }
});
