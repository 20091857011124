

























import analytics from "@/services/analytics";
import { defineComponent, computed, PropType } from "@vue/composition-api";
import { getOnlineRoom } from "@/helpers/projectUtils";

export default defineComponent({
  name: "StartOnlineSessionButton",
  props: {
    overrideRoomUrl: {
      type: String as PropType<string>,
      required: true,
    },
    projectId: {
      type: String as PropType<string>,
      required: true,
    },
    size: {
      type: String as PropType<string>,
      default: "small"
    }
  },
  setup(props) {
    const URL = computed(() => {
      if (props.overrideRoomUrl) {
        return props.overrideRoomUrl;
      }
      else {
        return "https://online.learnlink.no/" + getOnlineRoom(props.projectId);
      }
    });

    function launchOnline() {
      analytics.track("startOnlineSession", { projectID: props.projectId });
      const win = window.open(URL.value, "_blank");

      win?.focus();
    }

    return {
      launchOnline,
      URL,
    };
  },
});
