

























import { computed, defineComponent, onMounted, PropType, ref } from "@vue/composition-api";
import { useModule } from "@/store/hooks";
import { userModule } from "@/store/modules/user";
import authModule from "@/store/modules/auth";
import Learnlink from "@learnlink/interfaces";
import Message from "../Message.vue";
import Moment from "@/services/moment";

export default defineComponent({
  components: { Message },
  name: "TeamInformation",
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.FullMetadata>,
      required: true
    }
  },
  setup(props) {
    const sellerMap: Record<string, Learnlink.Seller.ForTeam> = {};
    const { state } = useModule(authModule);
    const wrapper = ref(null);

    props.team.verboseSellers.forEach((seller) => {
      sellerMap[seller.uid] = seller;
    });

    const messages = computed(() => {
      return props.team.information.filter((info) => info.deleted === 0).map((info) => {
        return {
          created: info.created,
          name: sellerMap[info.author] ? sellerMap[info.author].firstName : "Learnlink",
          photoURL: sellerMap[info.author] ? sellerMap[info.author].photoURL : "/img/learnlink_icon_large.png",
          text: info.text,
          from: info.author
        };
      });
    });

    onMounted(() => {
      if (!state.simulate) {
        const updatedLastReadTeamMessages: Learnlink.User.ReadTeamMessages[] =
          userModule.state.user.lastReadTeamMessages?.filter(o => o.teamID !== props.team.ID) || [];

        updatedLastReadTeamMessages.push({ teamID: props.team.ID, read: Moment().unix() });
        userModule.actions.updateUserObject({ lastReadTeamMessages: updatedLastReadTeamMessages });
      }

      setTimeout(() => {
        if (wrapper.value !== null) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: Object is possibly 'null'.
          wrapper.value.scrollTop = wrapper.value.scrollHeight;
        }
      }, 200);
    });

    const isSeller = userModule.state.user.seller;

    return {
      isSeller,
      messages,
      wrapper
    };
  },
});
