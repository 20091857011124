






















































































import { defineComponent, onMounted, PropType } from "@vue/composition-api";
import { formatUnixTimestamp } from "@/helpers/timeUtils";
import { markRead } from "@/api/messageService";
import { useModule } from "@/store/hooks";
import { userModule } from "@/store/modules/user";
import authModule from "@/store/modules/auth";
import Learnlink from "@learnlink/interfaces";
import ProfilePhoto from "./profile/ProfilePhoto.vue";

export default defineComponent({
  components: { ProfilePhoto },
  name: "Message",
  props: {
    message: {
      type: Object as PropType<Learnlink.Message.FullViewApp>,
      required: true
    }
  },
  setup(props) {
    const { state } = useModule(authModule);

    onMounted(() => {
      if (props.message.ID && !props.message.read && props.message.from !== userModule.state.userId && !state.simulate) {
        markRead(props.message.ID);
      }
    });

    return {
      formatUnixTimestamp,
      uid: userModule.state.userId
    };
  }
});
